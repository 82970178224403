import React from 'react';


import './style-modal.css'

function ModalSeo({ isOpenSeo, onClose }) {
    if (!isOpenSeo) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button onClick={onClose}>X</button>
                <div className="modal-header">
                    <h2>SEO & Marketing Digital: Impulsionando o Crescimento do Seu Negócio</h2>

                    <p>Bem-vindo ao mundo do SEO e Marketing Digital, onde a otimização de mecanismos de busca aprimora a visibilidade nos motores de busca e o Marketing Digital envolve o público-alvo por meio de estratégias eficazes. Na <strong>CODEON</strong>, estamos comprometidos em impulsionar o crescimento do seu negócio por meio dessas poderosas ferramentas digitais. Continue lendo para descobrir como podemos aumentar a presença online da sua empresa.</p>

                    <h2>Nossos Serviços de SEO & Marketing Digital</h2>

                    <p>Na <strong>CODEON</strong>, oferecemos uma ampla gama de serviços personalizados para atender às suas necessidades de SEO e Marketing Digital. Isso inclui otimização de mecanismos de busca para aumentar sua visibilidade online, estratégias de marketing de conteúdo para envolver seu público-alvo, publicidade online eficaz e muito mais. Nossa equipe de especialistas está pronta para impulsionar sua presença digital.</p>

                    <h2>Otimização de Mecanismos de Busca (SEO)</h2>

                    <p>O SEO é a chave para alcançar os melhores resultados nos motores de busca. Utilizamos as mais recentes práticas e técnicas de SEO para melhorar seu ranking nos mecanismos de busca, aumentar o tráfego orgânico e conquistar a confiança dos usuários.</p>

                    <h2>Marketing Digital Estratégico</h2>

                    <p>Nosso Marketing Digital estratégico envolve a criação de campanhas direcionadas que alcançam o público certo no momento certo. Utilizamos análises de dados e estratégias personalizadas para aumentar a conversão e o engajamento do público.</p>


                    <h2>Metodologia de Trabalho</h2>

                    <p>Em cada projeto, seguimos uma metodologia comprovada que envolve pesquisa de mercado, análise de concorrência, estratégias de palavras-chave, criação de conteúdo relevante e análise contínua de desempenho. Garantimos que sua presença online seja otimizada para resultados de negócios reais.</p>

                    <h2>Nossa Equipe de Especialistas</h2>

                    <p>Nossa equipe de especialistas em SEO e Marketing Digital é composta por profissionais experientes e apaixonados pelo que fazem. Eles têm conhecimento abrangente em otimização de mecanismos de busca, publicidade online e estratégias de marketing digital.</p>

                    <h2>Solicite um Orçamento</h2>

                    <p>Pronto para impulsionar seu negócio online? Entre em contato conosco para discutir suas necessidades de SEO e Marketing Digital. Estamos ansiosos para ajudar sua empresa a crescer.</p>



                </div>

            </div>
        </div>
    );
}

export default ModalSeo;
