import React from 'react';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';

import Projetos from './components/Projetos';
import Feedback from './components/Feedbacks';
import Teams from './components/Teams';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Services />
      <Projetos />
      <Feedback />
      <Teams />
      <Footer />
    </div>
  );
}

export default App;
