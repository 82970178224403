import React, { useState } from 'react'
import './style.css'


import logo from '../../assets/images/logo-small.png'
import man from '../../assets/images/man.png'
import seta from '../../assets/images/seta.svg'


import react from '../../assets/images/skills/react.svg'
import js from '../../assets/images/skills/js.svg'
import html from '../../assets/images/skills/html.svg'
import css from '../../assets/images/skills/css.svg'
import figma from '../../assets/images/skills/figma.svg'
import mongo from '../../assets/images/skills/mongo.svg'
import mysql from '../../assets/images/skills/mysql.svg'
import java from '../../assets/images/skills/java.svg'
import node from '../../assets/images/skills/node.svg'
import angular from '../../assets/images/skills/angular.svg'



import vector from '../../assets/images/elements/vector.svg'
import vector2 from '../../assets/images/elements/vector2.svg'
import retangulo from '../../assets/images/elements/retangulo.svg'
import retangulo2 from '../../assets/images/elements/retangulo2.svg'


import Header from '../Header/index'
import Modal from './Modal'

function Home() {


    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
    });

    const handleSaibaMaisClick = (title, content) => {
        setModalContent({ title, content });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="containerHome" id='inicio'>
                <Modal isOpen={isModalOpen} onClose={handleCloseModal} title={modalContent.title} content={modalContent.content} />
                <Header className='header' />
                <div className="cardLeft" >
                    <img className='img-logo' src={logo} alt='logo' />
                    <section className='contentLeft'>
                        <p>Melhor Agência no <span className='nameBrasil'>Brasil</span></p>
                        <h2>Agência Digital</h2>
                        <h3>& Soluções.</h3>
                        <p>Somos a empresa que desenvolve aplicativos e softwares de acordo com o seu modelo de negócio, com qualidade, tecnologia moderna, segura e eficiente.</p>
                    </section>
                    <div className='homeBtn'>
                        <button
                            className='btnJoin'
                            onClick={() => handleSaibaMaisClick('Iniciar', '.......')}>
                            Iniciar <span><img src={seta} alt='seta' /></span>
                        </button>
                        {/* <p>conheça mais!<span><img src={setaBK} alt='seta' ></img> </span></p> */}

                    </div>
                </div>
                <div className="cardRight">
                    <div className='vectorHome'>
                        <img src={vector} alt='vector' />
                    </div>
                    <div className='vectorHome2' >
                        <img src={vector2} alt='vector2' />
                    </div>
                    <div className='retanguloHome'>
                        <img src={retangulo} alt='retangulo' />
                    </div>
                    <div className='retanguloHome2' >
                        <img src={retangulo2} alt='retangulo2' />
                    </div>
                    <div>
                        <img src={man} className='man' alt='man' />
                    </div>
                </div>
                <div className="skills">
                    <img src={html} alt='html' />
                    <img src={css} alt='css' />
                    <img src={js} alt='js' />
                    <img src={react} alt='react' />
                    <img style={{ width: 100, height: 100 }} src={node} alt='node' />
                    <img style={{ width: 100, height: 100 }} src={angular} alt='angular' />
                    <img style={{ width: 100, height: 100 }} src={java} alt='java' />
                    <img src={figma} alt='figma' />
                    <img style={{ width: 100, height: 100 }} src={mongo} alt='mongo' />
                    <img style={{ width: 100, height: 100 }} src={mysql} alt='mysql' />
                </div>
            </div>
        </>
    )
}

export default Home