import React from 'react'

const Modal = ({ isOpen, onClose, title, content }) => {
    if (!isOpen) return null;

    return (

        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">

                    <button onClick={onClose}>Fechar</button>
                </div>
                <div className="modal-content">

                </div>
            </div>
        </div>
    );
}

export default Modal