import React, { useState } from 'react'

import './style.css'

import seta from '../../assets/images/seta-green.svg'
import one from '../../assets/images/projetos/11.png'
import two from '../../assets/images/projetos/22.png'
import three from '../../assets/images/projetos/33.png'
import four from '../../assets/images/projetos/44.png'
import five from '../../assets/images/projetos/55.png'

import Modal from '../Services/Modal'


function Projetos() {

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
    });

    const handleSaibaMaisClick = (title, content) => {
        setModalContent({ title, content });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    }
    return (
        <div className="containerProjetos" id='portifólio'>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} title={modalContent.title} content={modalContent.content} />
            <div className="headerProjetos">
                <div className="hf">
                    <h3>Projetos</h3>
                    <h4>Galeria projetos recentes</h4>
                </div>
                <div className='hf2'>
                    {/* <button
                        onClick={() => handleSaibaMaisClick('Alguns de nossos projetos', 'Você vai amar trabalhar conosco!')}>
                        Saiba mais <span><img src={seta} alt='seta' /></span>
                    </button> */}
                </div>
            </div>

            <div className="carroselProjetos">
                <div className="projetoOne">
                    <img src={one} alt='projeto'></img>
                </div>
                <div className="projetoTwo">
                    <img src={two} alt='projeto'></img>
                </div>
                <div className="projetoThree">
                    <img src={three} alt='projeto'></img>
                </div>
                <div className="projetoFour">
                    <img src={four} alt='projeto'></img>
                </div>
                <div className="projetoFive">
                    <img src={five} alt='projeto'></img>
                </div>
            </div>

        </div>


    )
}


export default Projetos