import React from 'react';


import './style-modal.css'


import computer from '../../assets/images/icones/computer.svg'
import designer from '../../assets/images/icones/designer.svg'
import app from '../../assets/images/services/app.svg'
import seo from '../../assets/images/icones/seo.svg'

function Modal({ isOpen, onClose, title, content }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modalHome">
                <div className="modal-headerHome">
                    <div class="cardHome">
                        <div class="circleHome"></div>
                        <div class="circleHome"></div>
                        <div class="card-innerHome">
                            <img src={computer} alt='computer' />
                            <h2>Desenvolvimento Web</h2>
                            <p>Dominío próprio</p>
                            <p>Emails corporativos</p>
                            <p>Hospedagem segura</p>
                            <p>Certificados SSH</p>
                            <p>Design Profissional</p>
                            <p>Suporte 24HR</p>
                            <button className='btnModalHome'>CONTRATAR</button>
                        </div>
                    </div>
                    <div class="cardHome">
                        <div class="circleHome"></div>
                        <div class="circleHome"></div>
                        <div class="card-innerHome">
                            <img src={app} alt='computer' />
                            <h2>Desenvolvimento APP</h2>
                            <p>Apps Personalizados</p>
                            <p>Design Intuitivo</p>
                            <p>Compatibilidade Multiplataforma</p>
                            <p>Segurança Avançada</p>
                            <p>Atualizações Contínuas</p>
                            <p>Suporte Especializado</p>
                            <button className='btnModalHome'>CONTRATAR</button>
                        </div>
                    </div>
                    <div class="cardHome">
                        <div class="circleHome"></div>
                        <div class="circleHome"></div>
                        <div class="card-innerHome">
                            <img src={seo} alt='computer' />
                            <h2>SEO & Marketing</h2>
                            <p>Otimização de MB</p>
                            <p>Estratégias MD</p>
                            <p>Tags Eficientes</p>
                            <p>Análise de Concorrência</p>
                            <p>Relatórios de Desempenho</p>
                            <p>Resultados Mensuráveis</p>
                            <p>Suporte 24HR</p>
                            <button className='btnModalHome'>CONTRATAR</button>
                        </div>
                    </div>
                    <div class="cardHome">
                        <div class="circleHome"></div>
                        <div class="circleHome"></div>
                        <div class="card-innerHome">
                            <img src={designer} alt='computer' />
                            <h2>Designer Gráfico</h2>
                            <p>Criação de logo</p>
                            <p>Banners e Folders</p>
                            <p>Estilização de projetos</p>
                            <p>Design Profissional</p>
                            <p>Entregas Rápidas</p>
                            <p>Suporte 24HR</p>
                            <button className='btnModalHome'>CONTRATAR</button>
                        </div>
                    </div>
                    <button
                        className='close'
                        onClick={onClose}

                    >
                        X
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
