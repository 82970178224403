import React from 'react';


import './style-modal.css'

function ModalWeb({ isOpenWeb, onClose }) {
    if (!isOpenWeb) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button onClick={onClose}>X</button>
                <div className="modal-header">
                    <h2>Desenvolvimento Web: Transformando Ideias em Realidade</h2>

                    <p>Bem-vindo ao mundo da tecnologia da informação, onde o desenvolvimento web é o alicerce que impulsiona a inovação e a transformação digital. Na <strong>CODEON</strong>, estamos comprometidos em oferecer soluções de desenvolvimento web de alta qualidade que atendem às necessidades de nossos clientes. Continue lendo para descobrir como podemos ajudar você a alcançar seus objetivos digitais.</p>

                    <h2>Nossos Serviços de Desenvolvimento Web</h2>

                    <p>Na <strong>CODEON</strong>, oferecemos uma ampla gama de serviços de desenvolvimento web sob medida para atender às suas necessidades. Isso inclui o desenvolvimento de sites atraentes, aplicativos web personalizados, lojas virtuais robustas e muito mais. Nossa equipe de desenvolvedores experientes está pronta para transformar suas ideias em realidade.</p>

                    <h2>Tecnologias de Ponta</h2>

                    <p>Nossa empresa utiliza as mais recentes tecnologias e linguagens de programação no desenvolvimento web. Estamos atualizados com as tendências do setor, garantindo que seus projetos sejam construídos com as melhores práticas e padrões atuais.</p>

                    <h2>Metodologia de Trabalho</h2>

                    <p>Em cada projeto, seguimos uma metodologia rigorosa que inclui análise de requisitos, design atraente, desenvolvimento robusto, testes minuciosos e implementação eficiente. Garantimos que cada etapa seja executada com excelência.</p>

                    <h2>Nossa Equipe de Especialistas</h2>

                    <p>Nossa equipe de desenvolvimento web é composta por profissionais altamente qualificados e apaixonados pelo que fazem. Eles têm uma vasta experiência em projetos web e estão prontos para superar suas expectativas.</p>


                </div>

            </div>
        </div>
    );
}

export default ModalWeb;
