import React, { useState } from 'react'

import './style.css'

import seta from '../../assets/images/seta.svg'

import funcionarioOne from '../../assets/images/teams/funcionarioOne.png'
import funcionarioTwo from '../../assets/images/teams/funcionarioTwo.png'
import funcionarioThree from '../../assets/images/teams/funcionarioThree.png'
import funcionarioFour from '../../assets/images/teams/funcionarioFour.png'
import Modal from './Modal'

function Teams() {


    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
    });

    const handleSaibaMaisClick = (title, content) => {
        setModalContent({ title, content });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="containerTeams">
            <div className="titleTeams">
                <h3>Membros do time</h3>
                <h4>Conheça nossa equipe</h4>
                <p>Conheça nossa equipe talentosa e dedicada, pronta para transformar suas ideias em realidade. </p>
            </div>
            <div className="containerFuncionarios">
                <div className="func">
                    <img src={funcionarioOne} alt='func1' />
                    <h3>Martha</h3>
                    <p>Designer Gráfica</p>
                </div>
                <div className="func">
                    <img src={funcionarioTwo} alt='func2' />
                    <h3>Henrique</h3>
                    <p>Developer Web / App</p>
                </div>
                <div className="func">
                    <img src={funcionarioThree} alt='func3' />
                    <h3>Cristiano</h3>
                    <p>Gerente de processos</p>
                </div>
                <div className="func">
                    <img src={funcionarioFour} alt='func4' />
                    <h3>Fernanda</h3>
                    <p>Marketing Digital</p>
                </div>
            </div>
            <div className="joinUs">
                <button
                    className='btnJoin'
                    onClick={() => handleSaibaMaisClick('Preencha seus dados', 'Você vai amar trabalhar conosco!')}>
                    Junte-se a nós <span><img src={seta} alt='seta' /></span>
                </button>

            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} title={modalContent.title} content={modalContent.content} />
        </div>
    )
}

export default Teams