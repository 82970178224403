import React from 'react'

import logo from '../../assets/images/logo-small.png'

import whats from '../../assets/images/icones/whats.svg'

import './style.css'

function Footer() {


    const canal = ['Blog', 'Newletters', 'Suporte']
    return (
        <div className="containerFooter" id='contato'>
            <div className="containerContent">
                <div className="columnOne">
                    <img src={logo} alt='' />
                    {/* <p>Contrate já um de nossos serviços!</p> */}
                </div>
                <div className="columnTwo">
                    <div className="contact-container">
                        <button className='contact'>
                            <img src={whats} alt='logo-whatsApp' />
                            <h3>41 3798-7055</h3>
                        </button>

                    </div>
                    <div className='adress'>
                        <p>Rua Visconde de Nácar, 1440 - </p>
                        {/* <p>Centro</p> */}
                        <p style={{ color: '#fff', fontSize: '1rem' }}>
                            Curitiba-PR</p>
                  
                    </div>
                </div>
                <div className="columnThree">
                    <h3>Newsletter</h3>
                    <div className='inputFooter'>
                        <input type="text" placeholder='Insira seu email...' />
                        <button>Inscreva-se</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer