import React from 'react';


import './style-modal.css'

function ModalApp({ isOpenApp, onClose }) {
    if (!isOpenApp) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button onClick={onClose}>X</button>
                <div className="modal-header">
                    <h2>Desenvolvimento de Aplicativos: Transformando Ideias em Experiências Móveis</h2>

                    <p>Bem-vindo ao emocionante mundo do desenvolvimento de aplicativos, onde a criatividade se encontra com a tecnologia para criar soluções móveis que transformam conceitos em experiências interativas. Na <strong>CODEON</strong>, estamos comprometidos em transformar suas ideias em aplicativos móveis funcionais e cativantes. Continue lendo para descobrir como podemos levar sua visão para o próximo nível digital.</p>

                    <h2>Nossos Serviços de Desenvolvimento de Aplicativos</h2>

                    <p>Na <strong>CODEON</strong>, oferecemos uma ampla gama de serviços de desenvolvimento de aplicativos sob medida para atender às suas necessidades. Isso inclui o desenvolvimento de aplicativos para Android e iOS, design de interface de usuário intuitiva, integração de recursos avançados e muito mais. Nossa equipe de desenvolvedores talentosos está pronta para transformar sua ideia em um aplicativo móvel de sucesso.</p>

                    <h2>Tecnologia de Ponta</h2>

                    <p>Estamos na vanguarda da tecnologia móvel, utilizando as mais recentes ferramentas e linguagens de programação no desenvolvimento de aplicativos. Nossa abordagem técnica garante que seu aplicativo seja compatível com os dispositivos mais recentes e ofereça desempenho excepcional.</p>

                    <h2>Metodologia de Trabalho</h2>

                    <p>Em cada projeto, seguimos uma metodologia sólida que envolve planejamento cuidadoso, design meticuloso, desenvolvimento eficiente, testes rigorosos e lançamento suave. Garantimos que seu aplicativo seja construído com excelência em todas as etapas do processo.</p>

                    <h2>Nossa Equipe de Especialistas</h2>

                    <p>Nossa equipe de desenvolvimento de aplicativos é composta por profissionais altamente qualificados e apaixonados pelo desenvolvimento móvel. Eles têm uma vasta experiência em projetos de aplicativos e estão prontos para tornar sua visão uma realidade móvel.</p>

                    <h2>Solicite um Orçamento</h2>

                    <p>Pronto para dar vida à sua ideia de aplicativo? Entre em contato conosco para discutir seu projeto de desenvolvimento de aplicativos. Estamos ansiosos para trabalhar com você e transformar sua visão em uma experiência móvel interativa.</p>



                </div>

            </div>
        </div>
    );
}

export default ModalApp;
