import React, { useState } from 'react'
import './style.css'

import seta from '../../assets/images/seta.svg'

import computer from '../../assets/images/icones/computer.svg'
import designer from '../../assets/images/icones/designer.svg'
import app from '../../assets/images/services/app.svg'
import seo from '../../assets/images/icones/seo.svg'

import vector from '../../assets/images/elements/vector.svg'
import vector2 from '../../assets/images/elements/vector2.svg'
import quadrado35 from '../../assets/images/elements/quadrado35.svg'
import circle35 from '../../assets/images/elements/circle35.svg'
import circle20 from '../../assets/images/elements/circle20.svg'
import triangulo from '../../assets/images/elements/triangulo.svg'

import ModalApp from './ModalApp';
import ModalWeb from './ModalWeb'
import ModalSeo from './ModalSeo'
import ModalDesign from './ModalDesign';


function Services() {
    const [isModalWeb, setModalWeb] = useState(false);
    const [isModalApp, setModalApp] = useState(false);
    const [isModalSeo, setModalSeo] = useState(false);
    const [isModalDesign, setModalDesign] = useState(false);

    const handleWeb = () => {
        setModalWeb(true);
    };
    const handleApp = () => {
        setModalApp(true);
    };
    const handleSeo = () => {
        setModalSeo(true);
    };
    const handleDesign = () => {
        setModalDesign(true);
    };

    const handleCloseWeb = () => {
        setModalWeb(false);
    };
    const handleCloseApp = () => {
        setModalApp(false);
    };
    const handleCloseSeo = () => {
        setModalSeo(false);
    };
    const handleCloseDesign = () => {
        setModalDesign(false);
    };
    return (
        <>
            <div className="containerServices" id='serviços'>

                <img src={vector} alt='vector' className='vector' />
                <img src={vector2} alt='vector2' className='vector2' />
                <section className='cardsContainer' >
                    <div className="cardColumnOne">
                        <div className="card">
                            <img src={computer} alt='computer' />
                            <h2> Desenvolvimento de WEB</h2>
                            <p>No universo da tecnologia da informação, o desenvolvimento web é o alicerce que impulsiona a inovação e a transformação digital.</p>
                            <button
                                onClick={() => handleWeb()
                                }>
                                Saiba mais <span><img src={seta} alt='seta' /></span>
                            </button>
                        </div>
                        <div className="card">
                            <img src={seo} alt='seo' />
                            <h2> SEO & Marketing</h2>
                            <p>O SEO melhora a visibilidade nos motores de busca, enquanto o Marketing Digital engaja o público-alvo por meio de estratégias eficazes. Juntos, impulsionam o crescimento dos negócios.</p>
                            {/* <button>Saiba mais <span><img src={seta} alt='seta' /></span></button> */}
                            <button
                                onClick={() => handleSeo()}>
                                Saiba mais <span><img src={seta} alt='seta' /></span>
                            </button>
                        </div>

                    </div>
                    <div className="cardColumnTwo">
                        <div className="card">
                            <img src={app} alt='network' />
                            <h2>Desenvolimento App</h2>
                            <p>O desenvolvimento de aplicativos é a ponte entre a ideia e a realidade digital, criando soluções móveis que transformam conceitos em experiências interativas.</p>
                            <button
                                onClick={() => handleApp()}>
                                Saiba mais <span><img src={seta} alt='seta' /></span>
                            </button>
                        </div>
                        <div className="card">
                            <img src={designer} alt='designer' />
                            <h2> Designer Gráfico</h2>
                            <p>O design gráfico é a linguagem visual que transforma conceitos em imagens, comunicando mensagens impactantes por meio de cores, formas e tipografia.</p>
                            <button
                                onClick={() => handleDesign()}>
                                Saiba mais <span><img src={seta} alt='seta' /></span>
                            </button>
                        </div>
                    </div>

                </section>
                <section className='descriptionServices'>
                    <p>Serviços</p>
                    <h2>Impulsione o seus negócios conosco!</h2>
                    <p>Nossa equipe altamente qualificada está dedicada a planejar e executar o melhor para o seu negócio. Utilizamos as mais modernas ferramentas e expertise em desenvolvimento de software, web e design para criar soluções inovadoras e impactantes que impulsionam o sucesso da sua empresa. Conte conosco para transformar sua visão em realidade digital e elevar sua presença online a um novo nível.</p>
                    {/* <button
                        onClick={() => handleSaibaMaisClick('Designer Gráfico', 'O design gráfico é a linguagem visual que transforma conceitos em imagens, comunicando mensagens impactantes por meio de cores, formas e tipografia.')}>
                        Saiba mais <span><img src={seta} alt='seta' /></span>
                    </button> */}
                </section>

                <ModalWeb isOpenWeb={isModalWeb} onClose={handleCloseWeb} />
                <ModalApp isOpenApp={isModalApp} onClose={handleCloseApp} />
                <ModalSeo isOpenSeo={isModalSeo} onClose={handleCloseSeo} />
                <ModalDesign isOpenDesign={isModalDesign} onClose={handleCloseDesign} />
            </div>
        </>
    )
}

export default Services