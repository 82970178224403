import React, { useState } from 'react'

import './style.css'

// import star from '../../assets/images/feedbacks/star.svg'
import girl60 from '../../assets/images/feedbacks/girl60.png'
import girl80 from '../../assets/images/feedbacks/girl80.png'
import man60 from '../../assets/images/feedbacks/man60.png'
import girl92 from '../../assets/images/feedbacks/girl92.png'

import star from '../../assets/images/feedbacks/star.png'

import girl61 from '../../assets/images/feedbacks/girl61.png'
import girl81 from '../../assets/images/feedbacks/girl81.png'
import girl63 from '../../assets/images/feedbacks/girl62.png'
import girl93 from '../../assets/images/feedbacks/girl93.png'

function Feedback() {

    const feedbacks = [
        {
            text: "Excelente serviço! Nossa colaboração com a equipe foi incrível. Eles demonstraram grande profissionalismo e entregaram resultados excepcionais. Estamos extremamente satisfeitos com o trabalho deles e ansiosos para futuras parcerias.",
            author: "Otávio Silva",
            position: "CEO, Brabo",
        },
        {
            text: "A equipe do seu site de criação de software é excepcional. Eles conseguiram transformar nossas ideias em um software de alta qualidade que atendeu a todas as nossas necessidades. Recomendamos fortemente seus serviços.",
            author: "Mariana Santos",
            position: "Proprietária, Paulistano Pizza",
        },
        {
            text: "O marketing digital realizado pelo seu site é altamente eficaz. Nossa presença online cresceu significativamente desde que começamos a trabalhar com vocês. Obrigado por impulsionar nossos negócios!",
            author: "Ricardo Oliveira",
            position: "Diretor de Marketing, DigitalWorld",
        },
        {
            text: "A equipe de desenvolvimento de aplicativos do seu site é incrível. Eles criaram um aplicativo que superou nossas expectativas em termos de design e funcionalidade. Estamos muito satisfeitos com o resultado final.",
            author: "Ana Rodrigues",
            position: "Fundadora, ModaFitness",
        },
        {
            text: "A consultoria oferecida pelo seu site foi fundamental para o crescimento do nosso negócio. Recebemos orientações valiosas que nos ajudaram a tomar decisões estratégicas e alcançar nossos objetivos.",
            author: "Luiz Costa",
            position: "Gerente Comercial, GymFit",
        },
        // Adicione mais feedbacks aqui
    ];

    const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);

    const showNextFeedback = () => {
        setCurrentFeedbackIndex((prevIndex) =>
            prevIndex === feedbacks.length - 1 ? 0 : prevIndex + 1
        );
    };

    const showPreviousFeedback = () => {
        setCurrentFeedbackIndex((prevIndex) =>
            prevIndex === 0 ? feedbacks.length - 1 : prevIndex - 1
        );
    };

    const currentFeedback = feedbacks[currentFeedbackIndex];

    return (
        <div className="containerFeedback">
            <div className="pessoasLeft">
                <img className='man60' src={man60} alt='60' />
                <img className='girl80' src={girl80} alt='60' />
                <img className='girl60' src={girl60} alt='60' />
                <img className='girl92' src={girl92} alt='60' />
            </div>
            <div className="columnMiddle">
                <div className="titleFeedback">
                    <h3>Serviços</h3>
                    <h4>Feedbacks Recentes</h4>
                </div>
                <div className="boxFeedback">
                    <p>{currentFeedback.text}</p>
                    <div className="star">
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <h3>{currentFeedback.author}</h3>
                    <p>{currentFeedback.position}</p>
                    <div className="btnFeedbacks">
                        <button onClick={showPreviousFeedback}>Anterior</button>
                        <button onClick={showNextFeedback}>Próximo</button>
                    </div>
                </div>
            </div>
            <div className="pessoasRight">
                <img className='girl61' src={girl61} alt='60' />
                <img className='girl81' src={girl81} alt='60' />
                <img className='girl63' src={girl63} alt='60' />
                <img className='girl93' src={girl93} alt='60' />
            </div>

        </div>
    )
}


export default Feedback