import React from 'react';


import './style-modal.css'

function ModalDesign({ isOpenDesign, onClose }) {
    if (!isOpenDesign) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button onClick={onClose}>X</button>
                <div className="modal-header">
                    <h2>Design Gráfico: Transformando Conceitos em Imagens Impactantes</h2>

                    <p>Bem-vindo ao emocionante universo do design gráfico, onde a criatividade se funde com a linguagem visual para transformar conceitos em imagens poderosas. Na <strong>CODEON</strong>, estamos comprometidos em transformar sua visão em obras de arte visuais que comunicam mensagens impactantes por meio de cores, formas e tipografia. Continue lendo para descobrir como podemos dar vida às suas ideias por meio do design gráfico.</p>

                    <h2>Nossos Serviços de Design Gráfico</h2>

                    <p>Na <strong>CODEON</strong>, oferecemos uma ampla gama de serviços de design gráfico personalizados para atender às suas necessidades. Isso inclui criação de logotipos memoráveis, identidade visual de marca, materiais de marketing atraentes, design de embalagens e muito mais. Nossa equipe de designers talentosos está pronta para transformar sua visão em uma linguagem visual única.</p>

                    <h2>Criatividade e Inovação</h2>

                    <p>Nossa abordagem é alimentada pela criatividade e inovação. Utilizamos as ferramentas mais recentes e as tendências atuais do design para garantir que suas criações gráficas se destaquem e deixem uma impressão duradoura.</p>

                    <h2>Metodologia de Trabalho</h2>

                    <p>Em cada projeto, seguimos uma metodologia que envolve pesquisa, brainstorming, design conceitual, refinamento criativo e entrega impecável. Cada detalhe é cuidadosamente elaborado para garantir que seu design gráfico seja uma representação visual precisa de sua mensagem.</p>

                    <h2>Nossa Equipe de Designers Talentosos</h2>

                    <p>Nossa equipe de designers gráficos é composta por profissionais talentosos e apaixonados pelo design. Eles têm experiência em uma variedade de setores e estão prontos para dar vida às suas ideias por meio de sua criatividade.</p>

                    <h2>Solicite um Orçamento</h2>

                    <p>Pronto para transformar sua visão em um design gráfico impactante? Entre em contato conosco para discutir seu projeto. Estamos ansiosos para colaborar e criar designs gráficos excepcionais que comuniquem sua mensagem de maneira poderosa.</p>



                </div>

            </div>
        </div >
    );
}

export default ModalDesign;
