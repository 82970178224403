import React from 'react';


import './style-modal.css'


import computer from '../../assets/images/icones/computer.svg'
import designer from '../../assets/images/icones/designer.svg'
import app from '../../assets/images/services/app.svg'
import seo from '../../assets/images/icones/seo.svg'

function Modal({ isOpen, onClose, title, content }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <form class="form">
                <p className='closeTeam'>

                    <button

                        onClick={onClose}

                    >
                        X
                    </button>
                </p>
                <span class="form-title">Envie seu CV</span>
                <p class="form-paragraph">
                    O arquivo deve ser IMG ou PDF
                </p>
                <label for="file-input" class="drop-container">
                    <span class="drop-title">Solte os arquivos aqui!</span>
                    ou
                    <input type="file" accept="image/*" required="" id="file-input" />
                </label>
            </form>
        </div>
    )
}

export default Modal;
