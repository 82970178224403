import React from 'react'
import './style.css'

const menu = ['Inicio', 'Sobre', 'Portifólio', 'Serviços', 'Contato']

const Header = () => {

    return (
        <nav className='containerNav'>
            <ul className='container-options'>
                {menu.map((menu) => (
                    <li className='option'>
                        <a href={`#${menu}`.toLowerCase()} >{menu}</a>
                    </li>
                ))}

            </ul>
        </nav>
    )
}

export default Header