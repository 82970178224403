import React, { useState } from 'react'

import './style.css'

import reuniao from '../../assets/images/reuniao.png'
import seta from '../../assets/images/seta.svg'

import vector from '../../assets/images/elements/vector.svg'
import vector2 from '../../assets/images/elements/vector2.svg'
import Modal from '../Services/Modal'


function About() {


    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
    });

    const handleSaibaMaisClick = (title, content) => {
        setModalContent({ title, content });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <div className="containerAbout" id='sobre'>
                <Modal isOpen={isModalOpen} onClose={handleCloseModal} title={modalContent.title} content={modalContent.content} />
                <img src={vector} alt='vector' className='vectorAbout' />
                <img src={vector2} alt='vector2' className='vectorAbout2' />

                <section className='aboutLeft'>
                    <p>Sobre nós</p>
                    <h2>Buscando sempre o melhor!</h2>
                    <p>Somos a CodeOn, uma empresa de desenvolvimento de software e aplicativo que oferece soluções inovadoras e personalizadas para os seus negócios. Nossa missão é criar produtos que atendam às suas necessidades, superem as suas expectativas e gerem resultados positivos. Nossos valores são a qualidade, a criatividade, a ética e a satisfação do cliente.</p>
                    {/* <button
                        onClick={() => handleSaibaMaisClick('Sobre', '..........')}>
                        Saiba mais <span><img src={seta} alt='seta' /></span>
                    </button> */}
                </section>
                <div className='imgAbout'>
                    <img src={reuniao} alt='seta' ></img>
                </div>
            </div>
        </>
    )
}

export default About